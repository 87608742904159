import axios from "axios";
import config from "./config";

var md5 = require("md5");

async function login(email, password) {
	let body = {
		email: email,
		password: md5(password),
	};
	try {
		let response = await axios.post("/auth", body);
		let result = response.data;
		if (result?.data) {
			setAuthorizationToken(result?.data.token);
		}
		return result;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

function setAuthorizationToken(token) {
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
}

export default {
	login: login,
	setAuthorizationToken: setAuthorizationToken,
};
