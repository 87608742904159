import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));

const Login = lazy(() => import("../views/Login"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));
const ListSMS = lazy(() => import("../views/history/listSMS"));
const UserDetail = lazy(() => import("../views/user"));

/*****Routes******/

const ThemeRoutes = [
	{
		path: "/",
		element: <FullLayout />,
		children: [
			{
				path: "/",
				element: <Navigate to="listSMS" />,
			},
			{ path: "/listSMS", exact: true, element: <ListSMS /> },
			{ path: "/userDetail", exact: true, element: <UserDetail /> },
		],
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/change-password",
		element: <ChangePassword />,
	},
];

export default ThemeRoutes;
