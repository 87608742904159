import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/
const History = lazy(() => import("../views/admin/listSMS"));
const ManageUser = lazy(() => import("../views/admin/users"));
const ManageIP = lazy(() => import("../views/admin/manageIP"));
const ManageProvider = lazy(() => import("../views/admin/manageProvider"));
const CreateProvider = lazy(() => import("../views/admin/createProvider"));
const ProviderDetail = lazy(() => import("../views/admin/providerDetail"));
const TestSystem = lazy(() => import("../views/admin/testsystem"));
const Statistic = lazy(() => import("../views/admin/statistic"));

const UserDetail = lazy(() => import("../views/admin/userDetail"));
const CreateUser = lazy(() => import("../views/admin/createUser"));

const Login = lazy(() => import("../views/Login"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));

/*****Routes******/

const ThemeRoutes = [
	{
		path: "/",
		element: <FullLayout />,
		children: [
			{
				path: "/",
				element: <Navigate to="manageuser" />,
			},
			{ path: "/createuser", exact: true, element: <CreateUser /> },
			{ path: "/history", exact: true, element: <History /> },
			{ path: "/manageuser", exact: true, element: <ManageUser /> },
			{ path: "/manageIP", exact: true, element: <ManageIP /> },
			{
				path: "/manageprovider",
				exact: true,
				element: <ManageProvider />,
			},
			{
				path: "/createprovider",
				exact: true,
				element: <CreateProvider />,
			},
			{ path: "/userdetail/:id", exact: true, element: <UserDetail /> },
			{
				path: "/providerdetail/:id",
				exact: true,
				element: <ProviderDetail />,
			},
			{ path: "/testsystem", exact: true, element: <TestSystem /> },
			{ path: "/statistic", exact: true, element: <Statistic /> },
		],
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/change-password",
		element: <ChangePassword />,
	},
];

export default ThemeRoutes;
